html,
body,
#root,
.main {
    height:  100%;
    color: #ffffff;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea {
  resize: none;
}

.navigation-bar {
  background: radial-gradient(circle at top left, #394275 0%, #222650 100%);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  height: 100%;
  left: 0;

  &::after {
      content:  '';
      display: block;
      position: absolute;
      right:  0;
      top:  0;
      height: 100%;
      width:  1px;
      background: linear-gradient(180deg, #27316A 0%, #181C3C 100%);
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display:flex;
    height: 100%;
    flex-direction: column;
  }

  li {
    padding: 20px 0 20px 0;
    margin: 0;

    &.bottom {
      margin-bottom: 0;
      margin-top: auto;
    }

    a {
      display: block;
      padding: 16px;
      text-align: center;

      img {
        display: inline-block;
      }
    }
  }
}

.main {
    display: flex;
    height: 100%;
    background: radial-gradient(circle at top left, #394275 0%, #384073 21.73%, #353D6E 46.47%, #222650 100%);
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 60px;
}

.page-title, .college-title {
  font-family: 'Playfair Display', serif;
  h1 {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.71px;
  }

  h2 {
    font-size: 17px;
    letter-spacing: 0.78px;
  }
}

.college-title {
  font-weight: bold;
  color: rgba(255,255,255,0.49);
}

.container-scroll > .card-row {
  overflow-x: auto;
  white-space: nowrap;
}

.card {
  .container-scroll & { width: 460px; }
  width: 100%;
  border: none;
  background-color: rgba(255,255,255,0.04);
  box-shadow: 0 0 14px 0 rgba(34,38,80,0.12), 0 0 35px 6px rgba(34,38,80,0.12);
  border-radius: 13px;
  display: inline-block;
  vertical-align: top;
  white-space: normal;

  .presentation-slides & {
    width: 28%;
  }

  .card-title {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.8px;
    font-family: Helvetica Neue;
  }

  .card-subtitle {
    color: rgba(255,255,255,0.49);
      font-size: 13px;
  }

  .card-text {
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 28px;
  }

  &.Copernicus .card-title {
    color: #00DEFF;
  }
  &.Curie .card-title {
    color: #C55AFF;
  }
  &.Ada .card-title {
    color: #5FFF6B;
  }
  &.Tesla .card-title {
    color: #FFB751;
  }
  &.Gauss .card-title {
    color: #FF5D70;
  }
  &.Turing .card-title {
    color: #FFA5FD;
  }
  &.Schroedinger .card-title {
    color: #25D7DF;
  }
  &.Departments .card-title {
    color: #6781FF;
  }

  &:first-child {
    margin-left: 0 !important;
  }
}